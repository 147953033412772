.alert {
    padding: 10px 20px !important;
}

ngb-alert .alert-title {
    color: #856404;
    font-family: "DaimlerCSBold";
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
}

ngb-alert .alert-text {
    margin: 0;
    margin-top: 2px;
    color: #856404;
    font-family: "DaimlerCSRegular";
    font-size: 18px;
    line-height: 21px;
}
