@import "includeme";

ngb-datepicker.dropdown-menu {
  margin-top: 16px !important;
  margin-left: 14px !important;
}

.dropdown-menu.show {
  opacity: 1 !important;
}

.load-vin {
  .dropdown-menu.show {
    opacity: 1 !important;
    max-height: 370px;
    overflow: hidden;
    min-width: 255px;
    margin-top: 2px;
  }

  .dropdown-menu.show .active {
     background: $grey-76 !important;
  }
  
  ngb-highlight {
    font-size: 14px !important;
    font-family: 'DaimlerCSRegular' !important;
  }

}

.load-vin-filter {
  .dropdown-menu.show {
    min-width: 170px;
  }
  input {
    width: 170px;
  }
}

.ngb-dp-header {
  background-color: $dp-grey !important;
  border-radius: 4px 4px 0 0 !important;
}

.input-group .btn {
  color: $dp-blue !important;
}

select.custom-select {
  background: $grey-76;
  border-radius: 4px !important;
  margin-left: 4px !important;
  color: $white-soft !important;
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.ngb-dp-day .btn-light {
  outline: none !important;
  border: 0 !important;
}

.ngb-dp-day {
  outline: none !important;
  border: 0 !important;
}

.ngb-dp-weekday {
  color: #5bc0de !important;
}

.input-group .btn:focus {
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.ngb-dp-arrow {
  padding: 3px !important;
}

.ngb-dp-week {
  background: $dp-grey !important;
  border-radius: 4px 4px 0 0 !important;
}

.btn-light.bg-primary.text-white {
  background-color: $dp-blue !important;
}

.btn-light {
  color: $white-soft !important;
  background-color: $grey-76 !important;
}

.btn-light.text-muted.outside {
  color: $dark-grey !important;
}

.ngb-dp-day {
  background-color: $grey-76 !important;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
