@import "includeme";

.datatable-header {
  background: $background-black !important;
}

.datatable-header-cell {
  font-family: "DaimlerCSDemi" !important;
  color: $white-soft !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 18px;
  margin-left: 24px;
  letter-spacing: 0 !important;

  &:hover {
    color: #c2bebe !important;
  }
}

.checkbox-sort {
  .sort-btn {
    margin-left: 17px;
  }
}

.datatable-body {
  border: none !important;
}

.datatable-row-odd,
.datatable-row-even {
  margin-top: -2px;
  padding-bottom: 14px;
}

.datatable-row-wrapper {
  width: 100vw !important;
}

.datatable-row-wrapper:nth-child(2n) {
  background-color: rgba($white, 0.08);
}

.datatable-footer {
  background: $background-black !important;
  .active {
    color: $blue-green !important;
  }
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
  color: $white-soft !important;
}

.datatable-body-cell {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: $white-soft !important;
  margin-top: 14px;
  margin-left: 24px;
  overflow-y: hidden !important;
  & span.sdpaket {
    color: $bright-blue;
    &:hover {
      color: $blue-green;
      cursor: pointer;
    }
  }
}

.ngx-datatable .datatable-header {
  border-bottom: none;
}

.ngx-datatable.material .datatable-body .datatable-row-wrapper:hover {
  border-bottom: 1px solid $bright-blue;
  border-top: 1px solid $bright-blue;
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell.sort-active
  .datatable-header-cell-wrapper {
  color: $bright-blue;
}

.ngx-datatable .datatable-header .datatable-header-cell.sort-active .sort-btn {
  color: $bright-blue;
}

.ngx-datatable *,
.ngx-datatable :after,
.ngx-datatable :before {
  box-sizing: border-box;
  outline: none;
}

.ngx-datatable input.datatable-body-cell-input-active,
.ngx-datatable textarea.datatable-body-cell-input-active {
  border-radius: 4px;
  resize: none;
  border: none;
}

.ngx-datatable input.datatable-body-cell-input-active {
  width: 95%;
}

.datatable-row-odd input.datatable-body-cell-input-active,
.datatable-row-even input.datatable-body-cell-input-active,
.datatable-row-odd textarea.datatable-body-cell-input-active,
.datatable-row-even textarea.datatable-body-cell-input-active {
  color: $white;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: $white-soft;
  font-family: "DaimlerCSRegular";
}

.datatable-row-odd input.datatable-body-cell-input-active,
.datatable-row-odd textarea.datatable-body-cell-input-active {
  background-color: rgba($dark-grey, 0.69) !important;
}

.datatable-row-even input.datatable-body-cell-input-active,
.datatable-row-even textarea.datatable-body-cell-input-active {
  background-color: rgba($white, 0.08) !important;
}

datatable-body-cell.datatable-body-cell.sort-active.text-center {
  margin-right: 5px;
}

.ngx-datatable .datatable-body {
  max-height: 550px;
  overflow: hidden auto;
}

.empty-row {
  font-family: "DaimlerCSRegular";
  font-size: 14px;
  color: $white-soft;
  letter-spacing: 0;
  font-weight: 400;
  padding: 18px 0px 18px 64px;
}

/* dropdown result-datatable */
.result-table {
  .editable {
    margin-left: -3px;
  }
  .ngx-datatable .datatable-body {
    .ng-select {
      position: absolute !important;
    }
    .ng-select .ng-select-container {
      width: 80px !important;
      margin-left: -8px;
      margin-top: -2px;
    }
  }
  .ng-dropdown-panel {
    top: 2em;
    left: -8px;
    max-width: 80px;
  }
}

::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar */
  width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-clip: padding-box !important;
  background: $grey-76 !important;
  border: 8px solid #979797;
  border-radius: 4px !important;
  position: relative;
  left: -4px !important;
}

::-webkit-scrollbar-track-piece:end {
  opacity: 0.69;
  background: $dark-grey;
  margin-bottom: 0px !important;
}

::-webkit-scrollbar-track-piece:start {
  opacity: 0.69;
  background: $dark-grey;
  margin-top: 0px !important;
}

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

.scroll-container {
  position: relative;
}

.datatable-header-cell .checkbox-container {
  width: 16px !important;
}

.datatable-header-cell .checkradio-container {
  width: 20px !important;
}

.checkbox-container,
.checkradio-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox, default radio button */
.checkbox-container input,
.checkradio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid $dark-grey-99;
  border-radius: 4px;
}

/* Create a custom radiobutton */
.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $background-black;
  border-radius: 50%;
  margin: 0px;
}

/* On mouse-over, add a transparent background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a transparent background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}

/* On mouse-over, add a  background color */
.checkradio-container:hover input ~ .radiomark {
  background-color: $background-black;
}

/* When the radiomark is checked, add a  background */
.checkradio-container input:checked ~ .radiomark {
  background-color: $background-black;
}

/* Create the checkmark - radiomark/indicator (hidden when not checked) */
.checkmark:after,
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark - radiomark when checked */
.checkbox-container input:checked ~ .checkmark:after,
.checkradio-container input:checked ~ .radiomark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid $bright-blue;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the radiomark/indicator */
.checkradio-container .radiomark:after {
  left: 6px;
  top: 6px;
  width: 5px;
  height: 2px;
  border: solid $bright-blue;
  border-width: 3px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}

/**
	 * Footer custom Styles
	 */
.ngx-datatable.material .datatable-footer {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  overflow: hidden;
  @media screen and (max-width: 600px) {
    height: 90px !important;
  }
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  color: $blue-green !important;
  background-color: transparent;
  font-weight: bold;
  font-size: 15px;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  font-size: 14px;
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: $white-soft;
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: transparent;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-right,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
  color: $white-soft;
  &:hover {
    color: $silver;
  }
}

.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-right,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-skip {
  color: $blue-green;
  &:hover {
    color: $dark-blue;
  }
}

.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  float: left !important;
}

ul.pager {
  left: 9px;
  position: relative;
}

.ngx-datatable.material i.datatable-icon-right {
  margin-left: -17px;
}

.ngx-datatable.material i.datatable-icon-left {
  margin-left: -17px;
}

.ngx-datatable.material i.datatable-icon-skip {
  margin-left: -20px;
}

.ngx-datatable.material li.pages.active,
.ngx-datatable.material li.pages {
  position: relative;
  left: -12px;
}

.page-result-section {
  font-family: "DaimlerCSRegular";
  font-size: 14px;
  color: $white-soft;
  span.result-text {
    margin-right: 8px;
  }
  span.result-value {
    margin-right: 20px;
    color: $blue-green;
  }
  span.float-end {
    position: relative;
    right: 26px;
    @media screen and (max-width: 600px) {
      top: 20px;
    }
  }
}

.page-result-section.server-side,
.page-result-section.client-side {
  .margin-override {
    margin-right: 2px !important;
  }

  span.result-value {
    margin-right: 8px;
    color: $white-soft;
    &:hover,
    &:active {
      color: $blue-green !important;
      cursor: pointer !important;
    }
  }
  span.float-end {
    position: relative;
    right: 26px;
    @media screen and (max-width: 600px) {
      top: 20px;
    }
  }
}

.page-result-section.client-side {
  .margin-override {
    margin-right: 25px !important;
  }
}

.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  margin-right: 4px;
}

.editable {
  margin-left: -2px;
}

.header-sort {
  cursor: pointer !important;
}

.bdp-modal {
  .datatable-body-cell-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.datatable-row-detail {
  height: auto !important;
}

.spinner-dt-box {
  .spinner {
    width: 18px;
    height: 18px;
    color: $bright-blue;
    border-radius: 50%;
    position: relative;
  }
  .spinner-border {
    border-right-color: $white-hovered;
    border-left-color: $white-hovered;
    border-top-color: $white-hovered;
    animation: 1s linear infinite spinner-border !important;
  }
}
