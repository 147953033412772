// color
$dark-blue: #00677f;
$dark-blue-2: #04B2C2;
$bright-blue: #09d2e4;
$bright-blue-hover: #3994a6;
$dark-grey: #444444;
$dark-grey-99: #999999;
$light-grey: #c8c8c8;
$background-black: #292929;
$white: #ffffff;
$nav-background: #333333;
$grey-svg-default: #686868;
$white-soft: #fbfbfb;
$blue-soft: #49666c;
$danger: #ce5151;
$danger-70: #ff6e70;
$grey-disabled: #242424;
$grey-selected: #5e5e5e;
$grey-unselected: #626262;
$card-grey: rgba(68, 68, 68, 0.69);
$grey-hover: #5a5a5a;
$grey-opacity: #373737;
$grey-soft: #323232;
$grey-soft2: #434343;
$grey-soft3: #707070;
$grey-secondary: #636363;
$blue-green: #07A8B7;
$silver:#c2bebe;
$green: #24a04a;
$grey-76:#767676;
$white-hovered:#767676;
$black:#000000;

// toastr colors
$toast-red:#e1292b;

// Tile Colors
$tile-pink: #ac6395;
$tile-green: #33cc99;
$tile-orange: #ff9933;
$tile-bright-blue: #71c7d8;

// datepicker color
$dp-grey: #474747;
$dp-blue:#09D2E4;
