@font-face {
    font-family: 'DaimlerCSBold';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Bold.otf') format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSBoldItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-BoldItalic.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-BoldItalic.woff')
            format('woff');
}

@font-face {
    font-family: 'DaimlerCSDemi';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Demi.otf') format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-Demi.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSDemiItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-DemiItalic.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-DemiItalic.woff')
            format('woff');
}

@font-face {
    font-family: 'DaimlerCSExtraBold';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBold.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSExtraBoldItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBoldItalic.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBoldItalic.woff')
            format('woff');
}

@font-face {
    font-family: 'DaimlerCSItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Italic.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-Light.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSLight';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Light.otf') format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-Light.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSLightItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-LightItalic.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-LightItalic.woff')
            format('woff');
}

@font-face {
    font-family: 'DaimlerCSRegular';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Regular.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCS/DaimlerCSPro-Regular.woff') format('woff');
}


@font-face {
    font-family: 'DaimlerCARegular';
    src: url('assets/fonts/DaimlerCA/DaimlerCAPro-Regular.otf')
            format('opentype'),
        url('assets/fonts/DaimlerCA/DaimlerCAPro-Regular.woff') format('woff');
}

@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bs-stepper/dist/css/bs-stepper.min.css';
@import 'ngx-toastr/toastr';
@import 'styles/core';
@import 'styles/sidebar';
@import 'styles/includeme';
@import 'styles/datatable';
@import 'styles/datepicker';
@import 'styles/alert';
@import "@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";

////////////////////////////

div.add-button-container {
    position: relative;
    float: right;
    right: 46px;
    bottom: 24px;
    width: 48px;
    z-index: 1000;
}

.add-button,
.add-button:hover {
    z-index: 99;
    position: absolute;
    right: 0;
    border-radius: 50%;
    height: 48px !important;
    padding: 0 !important;
    width: 48px;
    color: #fbfbfb;
    text-align: center;
    background-color: $bright-blue;
    border: none;
    line-height: 0 !important;
    font-size: 12px !important;
}


// Data-Table
.datatable-scrollable-small {
    max-height: 340px;
    min-height: 340px;
}
.datatable-scroller {
    width: 100% !important;
}
.ngx-datatable .datatable-body {
    display: block;
    z-index: 10;
    min-height: auto !important;
}

.datatable-scroller {
    width: 100% !important;
}

.modal-body {
    .datatable-scroll {
        width: 100% !important;
    }
}

.datatable-scrollable .datatable-row-center,
.datatable-scrollable-small .datatable-row-center {
    padding-left: 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #999999;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #999999;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: #999999;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: #999999;
}

.tooltip.show {
    opacity: 1;
}

.note-tooltip .tooltip-inner {
    width: 500px;
    max-width: 156px;
    font-size: 12px !important;
    background-color: #999999;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,0.40);
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

// Icons
.icon-aqua {
    .svg-inline--fa {
        color: $bright-blue;
    }
}
.icon-container {
    .option-button {
        margin: 2px 0;
    }
}

// Modal
.modal-title {
    color: $white-soft;
    font-family: 'DaimlerCSRegular';
    font-size: 24px;
    line-height: 28px;
    opacity: 1;
}

.modal-content {
    background: $dark-grey;
    color: $white-soft;
    font-family: 'DaimlerCSRegular';
}

.modal-footer,
.modal-header {
    border: none;
}

.modal-header {
  button.close {
    padding: 0;
    margin: 0;
    color: $bright-blue;
    opacity: initial;

    &:hover {
      color: $bright-blue-hover
    }

    &:focus {
      outline: 0;
      opacity: initial !important;
    }

    &:active {
      outline: 0;
      color: $bright-blue-hover;
      border-color: $bright-blue-hover;
    }

    fa-icon {
      font-size: 24px;
    }
  }
}

.modal-body {
    padding:4px 32px 32px 32px;
    font-size: 14px;
}

.modal-header {
    padding: 24px 32px 8px;
}

.modal-footer {
    background-color: $background-black;
    border-radius: 0 0 4px 4px;
}

.modal-body,
.modal-header,
.modal-footer {
    color: $white-soft !important;
}

.modal-footer > * {
    margin: 0 -15px;
}
