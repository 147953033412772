@import "fcsc_colors";

body {
  font-family: "DaimlerCSRegular";
  overflow-x: hidden !important;
  color: $white;
  background: $background-black;
}

.m-0 {
  margin: 0;
}

.outer-container {
  background-color: rgba($dark-grey, 0.69);
  border: none !important;
  padding: 24px 24px 24px 24px !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 100% !important;
  &.sub-section {
    margin-top: 12px;
    margin-bottom: 67px;
  }

  .button-area {
    margin-top: 28px;
  }

  .col {
    display: flex;
    align-items: center;
  }
  .col.user {
    margin-left: -12px !important;
  }
  .title,
  .sub-title {
    color: $white;
    font-family: "DaimlerCSDemi";
    font-size: 24px;
    line-height: 28px;
    opacity: 1;
  }
  .title {
    font-size: 24px;
  }
  .sub-title {
    font-size: 16px;
  }
  .show-error {
    margin-top: 31px;
    color: $danger-70;
    span {
      font-family: "DaimlerCSRegular";
      font-size: 14px;
      color: $danger-70;
      letter-spacing: 0;
      font-weight: 400;
      display: flex;
      position: relative;
      left: 17px;
    }

    fa-icon {
      font-size: 18px !important;
      margin-top: 0.8px;
    }
  }
  .form-group {
    label {
      opacity: 0.72;
      font-size: 14px;
    }

    .date-background-editable {
      input {
        &[readonly],
        &:disabled{
         background: rgba($white-hovered, 0.99) !important;
        }
      }
    }
    input {
      opacity: 0.99;
      &[readonly],
      &:disabled {
        background-color: rgba($white-hovered, 0.32) !important;
      }
    }

    input::placeholder {
      color: $white-soft;
      font-size: 14px;
    }

    textarea {
      font-size: 12px !important;
      border: 1px solid $grey-76 !important;
      border-radius: 4px 4px 4px 4px !important;
      resize: none;
      &:focus {
        border: 1px solid $grey-76 !important;
      }
    }
  }
  .btn.justify {
    margin-top: 26px;
  }
}

a:hover {
  color: $white !important;
  opacity: 1 !important;
}

.card-title,
.container-title {
  color: $white;
  font-family: "DaimlerCSRegular";
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 14px;
  display: inline-block;
  padding-right: 8px;
}

a.toggle-icon {
  color: white;
  margin-left: 3rem;
}

button.close,
button.close:hover {
  color: $white;
  font-size: 1.8rem;
}

button.close:focus {
  outline: 0;
}

select::-ms-expand {
  display: none;
}

.dropdown-menu {
  background: #444444;
  opacity: 0.69;
}

.dropdown-item {
  color: white;
}

.dropdown-toggle::after {
  border: none !important;
  content: none;
}

.dropdown-item:hover {
  background: $grey-hover;
  color: white;
}

.option-button {
  background: transparent;
  padding: 0 6px;
  border: none;
  float: left;
  height: 20px;
  cursor: pointer;
  &:disabled {
    cursor: no-drop !important;
  }
  &:focus {
    outline: none;
  }
}

.option-svg path {
  transition: 0.25s;
  fill: $bright-blue;
}

.option-svg-info path {
  transition: 0.25s;
  fill: $white;
}

.option-svg:hover path {
  transition: 0.25s;
  fill: $blue-green;
}

.option-svg-disabled path {
  transition: 0.25s;
  fill: $blue-green;
}

.option-svg-info-hidden {
  visibility: hidden;
}

button:disabled .option-svg:hover path {
  transition: 0;
  fill: $grey-svg-default;
}

input.form-control,
select.form-control,
option.form-control {
  padding: 10px 16px;
  padding-bottom: 9px !important;
  line-height: 16px;
  background: $grey-76;
  border: 0px solid $grey-76 !important;
  color: $white-soft;
  font-size: 14px;
  border-radius: 4px;
  &::placeholder {
    color: $white-soft !important;
  }
}

.form-control.is-invalid {
  border: 2px solid $toast-red !important;
  border-radius: 6px;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  box-shadow: none !important;
}

.input-group > .form-select {
  padding-bottom: 9px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $dp-grey !important;
}

optgroup[label] {
  background: rgba(77, 77, 77, 0.2);
  font-family: "DaimlerCSRegular", sans-serif;
}

.form-control:focus {
  background-color: $grey-selected;
  border: 1px solid $white;
  color: $white-soft;
  opacity: 1;
  box-shadow: none !important;
}

.calculate-container {
  padding: 32px 48px 24px 48px;
  background: $card-grey;
}

textarea.form-control,
textarea.form-control:read-only {
  color: $white-soft;
  background: rgba(77, 77, 77, 0.2);
  border: 1px solid $grey-unselected;
}

textarea.form-control:read-only {
  cursor: default;
}

textarea.form-control:focus {
  border: 1px solid $white-soft;
  opacity: 1;
}

// Buttons
button.btn {
  font-family: "DaimlerCSDemi";
  color: $white-soft;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  padding: 0 auto;
  text-align: center;
  vertical-align: middle;
}

button.btn:not(:last-child) {
  margin-right: 16px;
}

button.btn.btn-primary {
  border: 1px solid $bright-blue;
  background-color: $bright-blue;
  outline: none !important;
  -webkit-appearance: none !important;
  box-shadow: none !important;
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    background-color: #04b2c2;
    -webkit-appearance: none !important;
  }
}

button.btn.btn-secondary,
button.btn.btn-secondary:active,
button.btn.btn-secondary:focus {
  background-color: transparent;
  color: $bright-blue;
  border: 1px solid $bright-blue;
  box-shadow: none;
}

button.btn.btn-primary:disabled,
button.btn.btn-secondary:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

button.btn-outline-primary {
  color: $bright-blue;
  border-color: $bright-blue;
}

button.btn-outline-primary:hover {
  color: $bright-blue;
  background: none;
  border-color: $bright-blue;
}

button.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 210, 228, 0.5);
}

button.close,
button.close:hover {
  color: $white;
  font-size: 1.8rem;
  box-shadow: none;
  border: none;
  background: transparent;
}

button.close:focus {
  outline: 0;
}

.btn-custom {
  position: relative;
  cursor: pointer;
  background: $grey-76;
  opacity: 0.99;
  border-radius: 4px;
  height: 34px;

  &.lg-size {
    width: 256px;
  }
  &.sm-size {
    width: 105px;
  }

  &:hover,
  &:active {
    outline: none !important;
    background-color: $grey-selected;
    -webkit-appearance: none !important;
  }

  fa-icon.ng-fa-icon {
    font-size: 11px !important;
    color: $white !important;
    float: right;
    margin-top: 10px;
    margin-right: 12px;
  }

  span {
    position: relative;
    top: 4px;
    left: 14px;
    font-size: 14px;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -1rem;
    left: 76%;
    padding: 7px;
    opacity: 1 !important;
    border: 1.5px solid $white;
    border-radius: 4px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid $bright-blue;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Page-Buttons
.round-button-container {
  width: 100%;
}

.round-button,
.round-button:hover {
  float: right;
  border-radius: 50%;
  height: 48px !important;
  padding: 0 !important;
  width: 48px;
  color: $white-soft;
  text-align: center;
  background-color: $bright-blue;
  border: none;
  line-height: 0em !important;
  font-size: 16px !important;
  cursor: pointer !important;
  margin: 0 0 16px 16px !important;
}

.round-button {
  &:disabled {
    opacity: 0.4;
  }
  &:focus {
    outline: none;
  }
}

fa-icon.round-button-icon {
  font-size: 24px;
}

fa-icon.scroll-to {
  font-size: 14px;
  color: $blue-green;
  cursor: pointer;
  margin-left: 10px;
}

.vus-card {
  background-color: $grey-opacity;
}

.vus-card-body {
  padding-left: 0;
  padding-right: 0;
}

.vus-title {
  margin-top: 12px;
  margin-left: 48px;
  margin-bottom: 28px;
}

.vus-title span {
  font-size: 24px;
  color: $white-soft;
}

.ng-fa-icon {
  font-size: 14px;
}

fa-icon.ng-fa-icon.dp {
  top: 0.3rem;
  position: relative;
  left: -22px;
  cursor: pointer;
}

.align-icons {
  margin-left: -4px;
}

.status-box {
  display: flex;
}

.status-text {
  margin-left: 8px;
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.uploader-modal,
.details-modal,
.confirmation-modal,
.edit-modal,
.cont-units-modal,
.dep-modal,
.bdp-edit-modal {
  .modal-lg {
    margin-top: 116px;
    max-width: 520px;
    max-height: 681px;
  }

  .modal-header {
    padding: 32px 40px 8px;
  }

  .modal-body {
    padding-left: 40px;
    textarea {
      background: $grey-76;
      font-size: 14px !important;
      border: 1px solid $grey-76 !important;
      border-radius: 4px 4px 4px 4px !important;
      resize: none;
      &:focus {
        border: 1px solid $grey-76 !important;
        background-color: $grey-selected !important;
      }
    }

    textarea.form-control.is-invalid {
      border: 2px solid $toast-red !important;
    }

    textarea.attr {
      padding-left: 0px !important;
      padding-top: 2px;
      height: 70px !important;
      overflow: auto !important;
      background: transparent !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items {
      font-family: "DaimlerCSRegular";
      font-size: 14px;
      color: $white;
    }

    fa-icon.ng-fa-icon.dp {
      top: 0.4rem !important;
    }

    .ng-select.ng-select-single
      .ng-select-container
      .ng-value-container
      .ng-value {
      position: relative;
      top: 1px;
    }

    ng-select .ng-dropdown-panel {
      width: 172px !important;
    }

    .filter {
      ng-select .ng-dropdown-panel {
        width: 140px !important;
      }
    }

    label {
      opacity: 0.72;
      font-size: 14px;
    }
    .add-space {
      margin-top: 24px;
      &.last {
        margin-bottom: 1.5rem;
      }
    }

    .custom-margin-b {
      margin-bottom: 14px;
    }

    .custom-margin-notification {
      margin-bottom: 40px;
    }

    .app-divider {
      height: 100%;
      width: 2px;
      background: $grey-76;
      margin: auto;
    }

    span.input-group-addon {
      padding-top: 6px;
      left: 10px;
      position: inherit;
    }

    span.space-span {
      margin-left: 18px;
    }
  }

  .modal-sub-text {
    font-family: "DaimlerCSRegular";
    font-size: 14px;
    padding: 0px 40px 8px;
    letter-spacing: 0;
    font-weight: 400;
  }
  .modal-text-body {
    line-height: 16px;
    margin-top: 20px;
    &.disable-opacity {
      opacity: 0.6;
    }
  }

  .sub-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "DaimlerCSDemi";
  }

  .button-area {
    display: flex;
    padding-top: 6px;
    padding-bottom: 4px;
    .displayed {
      margin-left: 0 !important;
      margin-right: 16px;
    }
  }

  input {
    &[readonly],
    &:disabled {
      background-color: rgba($white-hovered, 0.32) !important;
    }
  }

  .btn.justify {
    margin-top: 14%;
  }

  .custom-rad {
    .form-group.custom-radio {
      display: flex !important;
    }

    span.custom-radio-span {
      margin-left: 30px !important;
    }

    label.radio-inline.checkradio-container.custom-radio-lb {
      width: 100% !important;
    }

    .radiomark {
      background-color: $background-black !important;
    }

    .checkradio-container .radiomark:after {
      border-radius: 50% !important;
      left: 6px !important;
      top: 6px !important;
      width: 2px !important;
      height: 2px !important;
    }
  }
}

.cont-units-modal.override {
  .modal-lg {
    max-width: 1250px !important;
    .modal-content {
      padding-right: 10px;
    }
    .modal-footer {
      width: 100.2%;
      position: absolute;
      left: -1px;
      bottom: -3rem;
      .displayed {
        padding-right: 18px !important;
      }
    }
  }
  .data-table {
    input {
      &[readonly],
      &:disabled {
        background-color: transparent !important;
      }
    }
  }
}

.dep-modal {
  .modal-lg {
    max-width: 676px !important;
  }
  .button-area {
    .displayed {
      margin-right: 24px !important;
    }
  }
}

.details-modal {
  .modal-lg {
    max-width: 900px !important;
  }
}

.details-modal.override {
  .modal-lg {
    max-width: 1200px !important;
  }
}

.bdp-edit-modal {
  .modal-lg {
    max-width: 1033px !important;
  }
  .btn.justify {
    margin-top: 12% !important;
  }
}

.bdp-edit-modal.override {
  .modal-lg {
    max-width: 1042px !important;
  }
}

.cont-units-modal {
  .modal-lg {
    max-width: 728px !important;
  }
}

.entitlement-modal {
  .modal-md {
    margin-top: 5% !important;
  }
}
.ngx-file-drop__drop-zone {
  height: 245px !important;
  background: $background-black;
  border: 1px solid $bright-blue !important;
  border-radius: 4px !important;
}

.ngx-file-drop__content {
  color: $bright-blue !important;
  ul.list-group.droparea {
    position: relative;
    top: 74px;

    .list-group-item {
      font-family: "DaimlerCSRegular";
      font-size: 16px;
      color: $bright-blue !important;
      letter-spacing: 0;
      justify-content: center;
      display: flex;

      &.item-text {
        color: $white !important;
        font-size: 12px !important;
        opacity: 0.32;
        display: flex;
        flex-direction: row;
      }
      &.item-text:before,
      &.item-text:after {
        content: "";
        width: 121px !important;
        flex: 1 1;
        border-bottom: 2px solid $white;
        margin-right: 38px;
        margin-left: 38px;
        margin-top: auto;
        margin-bottom: auto;
      }

      & .item-icon {
        font-size: 24px !important;
        color: $bright-blue !important;
        justify-content: center;
        display: flex;
      }
    }
  }
}

.upload-area {
  background: $background-black;
  height: 245px !important;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 98%;
  scrollbar-color: $grey-76 #292929;

  li.list-group-item {
    margin-top: 8px;
    margin-bottom: -22px;
  }
}

.list-group-item {
  font-family: "DaimlerCSRegular";
  font-size: 12px;
  color: $white-soft !important;
  letter-spacing: 0;
  font-weight: 400;
  background-color: transparent !important;
  border: none !important;
}

ul.list-group.list-group-horizontal {
  .list-group-item {
    :last-child {
      color: $green !important;
      font-size: 10px !important;
    }
  }
  .error {
    :last-child {
      color: $danger !important;
      font-size: 10px !important;
    }
  }
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 440px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px !important;
  font-family: "DaimlerCSRegular" !important;
  color: $white !important;
  box-shadow: none !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}

.toast-error,
.toast-success {
  background-position-x: 20px !important;
  background-position-y: 16px !important;
  background-size: 18px 16px !important;
}

.toast-error {
  background-color: $toast-red !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E") !important;
}

button.toast-close-button {
  font-size: 32px !important;
  margin-top: 12px !important;
  margin-right: 12px !important;
  & :hover {
    color: $white-hovered !important;
  }
}

.popover-body {
  background: $grey-76 !important;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 50%) !important;
  border-radius: 4px !important;
  min-width: 238px !important;
  position: absolute;
  top: 41px;
  left: -86px;
}

.close-icon {
  font-size: 22px;
  color: $bright-blue;
  cursor: pointer;
  position: relative;
  left: 5px;
}

fa-icon.ng-fa-icon.edit {
  float: right;
  font-size: 12px !important;
  color: $bright-blue !important;
  display: flex;
  margin-top: 4px;
  padding-left: 11px;
  cursor: pointer;
  &:hover {
    color: $blue-green !important;
  }
}

.caret-spacer {
  fa-icon {
    cursor: pointer;
    position: relative;
    top: -2.25px;
    left: 16px;
  }
}

.caret-spacer-subsection {
  fa-icon {
    cursor: pointer;
    position: relative;
    top: -1.25px;
    left: 16px;
    font-size: 12px;
    margin: 0px;
  }
}

// ng-select
.ng-select .ng-select-container {
  font-family: "DaimlerCSRegular";
  background: $grey-76;
  color: $white;
  border: 0 !important;
  width: 172px !important;
  line-height: 16px !important;
  font-size: 14px;
  border-radius: 4px;
}

.log-level {
  .ng-select .ng-select-container {
    width: 280px !important;
  }

  ng-select .ng-dropdown-panel {
    width: 280px !important;
  }
}

.filter {
  .ng-select .ng-select-container {
    width: 140px !important;
  }
  .ng-dropdown-panel .scroll-host {
    border-radius: 4px !important;
  }
}

ng-select fa-icon {
  font-size: 10px !important;
  color: $green !important;
  position: relative;
  left: -2px;
}

ng-select span.ng-arrow-wrapper {
  padding-top: 6px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: $white;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: $grey-76 !important;
  border: 0 !important;
}

ng-select .ng-dropdown-panel {
  border: 0 !important;
  padding-top: 3px;
  background: $grey-76 !important;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent $white !important;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow,
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-top-color: $white !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
  padding: 0 15px;
}

.ng-dropdown-panel .ng-dropdown-panel-items,
ng-select .ng-select-container .ng-value-container .ng-input > input {
  font-family: "DaimlerCSRegular";
  font-size: 14px;
  color: $white;
  letter-spacing: 0;
  font-weight: 400 !important;
}

ng-select div.ng-value {
  margin-left: 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: $grey-76 !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: $grey-76 !important;
  font-weight: 400 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: $grey-76 !important;
  position: relative;
  left: 4%
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: $grey-76 !important ;
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: rgba($white-hovered, 0.32) !important;
  .ng-arrow-wrapper .ng-arrow {
    border: none !important;
  }
}

.scroll-to-section {
  padding-top: 1.5rem;
}

span.float-to-right {
  float: right !important;
  font-size: 14px;
  color: $white-soft;
}

// Language Switch custom Dropdown
.language-selector {
  line-height: 14px !important;
  margin-top: 0.5rem;
  padding: 0 auto;
  position: static;

  h5 {
    font-family: "DaimlerCSBold" !important;
    font-size: 18px !important;
    letter-spacing: 0;
    font-weight: 700;
    padding-top: 10px;
    padding-left: 6px;
  }

  .ng-select-container {
    background: transparent !important;
    width: 50px !important;
  }

  .ng-arrow-wrapper {
    display: none;
  }
  .ng-select.ng-select-opened > .ng-select-container {
    background: transparent !important;
  }
  .ng-dropdown-panel {
    .scroll-host {
      width: 200px;
      line-height: 40px;
    }
    &.ng-select-bottom {
      background: $grey-76;
      width: 238px;
      margin-top: 44px;
      margin-left: -24px;
      border-radius: 4px;
      z-index: 10000;
    }
    .ng-dropdown-header {
      border-bottom: 1px solid transparent;
    }
  }

  ng-select div.ng-value {
    margin-top: 47%;
    margin-left: 12px;
    padding: 0;
    cursor: pointer;
  }

  .ng-option,
  .ng-option-marked {
    font-family: "DaimlerCSBold" !important;
    font-size: 14px;
    color: $white-soft;
    letter-spacing: 0;
    font-weight: 700;
  }
  img {
    padding-right: 8px;
    padding-left: 3px;
  }
}

// file upload custom
.custom-uploader {
  file-upload {
    margin-top: 10% !important;
    height: 0px !important;
    border: none !important;
  }

  .upload-text {
    display: none !important;
  }

  .upload-button {
    font-family: "DaimlerCSDemi";
    overflow-x: hidden;
    background-color: $dark-grey !important;
    color: $bright-blue !important;
    border: 1px solid $bright-blue !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    min-width: 184px !important;
    height: 34px;
    padding-top: 3.1% !important;
    text-align: center !important;
    vertical-align: middle !important;
    overflow-y: hidden !important;
  }
}

.border-red {
  border: 2px solid $toast-red !important;
  border-radius: 6px;
}

label.checkbox-container {
  opacity: 1 !important;
  span.lb-alignment {
    margin-left: 32px;
    font-family: "DaimlerCSRegular";
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
    line-height: 1em;
  }
}

/* .bs-stepper */
.bs-stepper .line,
.bs-stepper-line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 7px;
  margin: auto;
  background-color: $dark-blue-2;
  opacity: 0.65 !important;
}

.bs-stepper .step-trigger {
  padding: 20px 0;
  &:disabled {
    opacity: 1 !important;
  }
}

.active .bs-stepper-circle {
  background-color: $bright-blue !important;
  opacity: 1 !important;
}

.bs-stepper-circle {
  margin: 0;
  font-size: 20px;
  background-color: $dark-blue-2;
  opacity: 0.65 !important;
  padding: 13px;
  border-radius: 50%;
}

.bs-stepper-header {
  padding: 1rem 2rem 0rem;
}

.bs-stepper-content {
  padding: 0 !important;
}

.justify-content-right {
  justify-content: right;
}

.position-relative {
  position: relative;
}

.space-span {
  margin-left: 18px;
}

.minimum-height {
  min-height: 60px;
}

.custom-pos-relative {
  top: 1rem;
  position: relative;
}


.date-background-editable {
  input {
    &[readonly],
    &:disabled{
     background: rgba($white-hovered, 0.99) !important;
    }
  }
}
